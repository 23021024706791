import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { distinctUntilChanged, scan } from 'rxjs/operators';

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpResponseHeader<T>(
  event: HttpEvent<T>
): event is HttpResponse<T> {
  return event.type === HttpEventType.ResponseHeader;
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  );
}

export interface Upload<T = unknown> {
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'FAIL' | 'DONE';
  body?: T | null;
}

export function upload<T>(): (
  source: Observable<HttpEvent<T>>
) => Observable<Upload<T>> {
  const initialState: Upload<T> = { state: 'PENDING', progress: 0 };
  const reduceState = (upload: Upload<T>, event: HttpEvent<T>): Upload<T> => {
    if (isHttpProgressEvent(event)) {
      return {
        progress: event.total
          ? Math.round((100 * event.loaded) / event.total)
          : upload.progress,
        state: 'IN_PROGRESS',
      };
    }
    if (isHttpResponseHeader(event)) {
      if (event.status !== 200) {
        return {
          progress: 0,
          state: 'FAIL',
        };
      }
    }
    if (isHttpResponse(event)) {
      const { body } = event;
      return {
        progress: 100,
        state: 'DONE',
        body,
      };
    }
    return upload;
  };
  return (source) =>
    source.pipe(
      scan(reduceState, initialState),
      distinctUntilChanged(
        (a, b) => a.state === b.state && a.progress === b.progress
      )
    );
}
